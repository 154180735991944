/**
 * Created by Kai on 12.11.2015.
 */
angular.module('MitPlanLogin', [
])
    .config(function ($locationProvider) {
        $locationProvider.html5Mode(true);
    })
    .controller('LoginCtrl', ['$scope', '$http', function ($scope, $http) {
        $scope.vm = {};
        $scope.vm.reset_success = false;

        $scope.vm.submit = function(){
            var login = {
                user: $scope.vm.username,
                pass: $scope.vm.password
            };

            try{
                var err = document.querySelector('.error-message');
                err.classList.remove('active');

                $http({
                    method: 'POST',
                    url: '/login',
                    data: login,
                    withCredentials: true
                }).then(function success(res){
                    console.log(JSON.stringify(res));
                    console.log(JSON.stringify(res.data));

                    var decoded = jwt_decode(res.data.token);
                    console.log(JSON.stringify(decoded));
                    window.localStorage.user = JSON.stringify(decoded);

                    //$cookies.put('mp-me', res.cookie);

                    if(res.cookie){
                        document.cookie = 'mp-me=' + res.cookie;
                    }

                    //var keks = $cookies.get('mp-me');
                    //console.log(keks);
                    console.log("KEKS", res.cookie);

                    window.localStorage.token = res.data.token;
                    window.location = "/";
                    //document.location.reload(true);
                }, function error(res){
                    console.log(res);

                    if(res.status === 403){
                        if(res.data.type){
                            if(res.data.type === "pass"){
                                var pw = document.querySelector('.pw-form');
                                pw.style.display = 'block';
                                var us = document.querySelector('.user-form');
                                us.style.display = 'none';
                            }
                            else{
                                err.classList.add('active');
                            }
                        }
                    }
                });
            }
            catch(ex){
                console.log('ERROR: ' + ex);
            }
        };

        $scope.vm.setpass = function(){
            var setpw = {
                pass1: $scope.vm.pass1,
                pass2: $scope.vm.pass2,
                user: $scope.vm.username
            };

            if(setpw.pass1 === setpw.pass2){
                $http({
                    method: 'POST',
                    url: '/pass',
                    data: setpw,
                    withCredentials: true
                }).then(function success(res){
                    var pw = document.querySelector('.pw-form');
                    pw.style.display = 'none';
                    var us = document.querySelector('.user-form');
                    us.style.display = 'block';
                });
            }
        };

        $scope.vm.submit_pw_reset = function(){
            $scope.vm.reset_success = false;
            $http({
                method: 'POST',
                url: '/reset',
                params: { user: $scope.vm.username_pw_reset }
            }).then(function success(res){
                $scope.vm.reset_success = true;
                console.log(JSON.stringify(res));
                console.log(JSON.stringify(res.data));
            }, function error(res){
                console.log(res);
                $scope.vm.reset_success = false;
            });
        }
    }])
       .directive('match', function ($parse) {
            return {
                require: '?ngModel',
                restrict: 'A',
                link: function(scope, elem, attrs, ctrl) {
                    if(!ctrl) {
                        return;
                    }

                    var matchGetter = $parse(attrs.match);
                    var caselessGetter = $parse(attrs.matchCaseless);
                    var noMatchGetter = $parse(attrs.notMatch);
                    var matchIgnoreEmptyGetter = $parse(attrs.matchIgnoreEmpty);

                    scope.$watch(getMatchValue, function(){
                        ctrl.$$parseAndValidate();
                    });

                    ctrl.$validators.match = function(){
                        var match = getMatchValue();
                        var notMatch = noMatchGetter(scope);
                        var value;

                        if (matchIgnoreEmptyGetter(scope) && !ctrl.$viewValue) {
                            return true;
                        }

                        if(caselessGetter(scope)){
                            value = angular.lowercase(ctrl.$viewValue) === angular.lowercase(match);
                        }else{
                            value = ctrl.$viewValue === match;
                        }
                        /*jslint bitwise: true */
                        value ^= notMatch;
                        /*jslint bitwise: false */
                        return !!value;
                    };

                    function getMatchValue(){
                        var match = matchGetter(scope);
                        if(angular.isObject(match) && match.hasOwnProperty('$viewValue')){
                            match = match.$viewValue;
                        }
                        return match;
                    }
                }
            };
        });
;
/**
 * Created by Kai on 12.11.2015.
 */
var translation_de = {
    header: 'Du musst dich anmelden.',
    credentials: 'Bitte gib Nutzername und Passwort ein.',
    username_placeholder: 'Nutzername',
    password_placeholder: 'Passwort',
    login_action: 'Anmelden',
    reset_action: 'Passwort vergessen?',
    error_userpass: 'Nutzername oder Passwort falsch'
};
;(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);throw new Error("Cannot find module '"+o+"'")}var f=n[o]={exports:{}};t[o][0].call(f.exports,function(e){var n=t[o][1][e];return s(n?n:e)},f,f.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
var Base64 = require('Base64');

function b64DecodeUnicode(str) {
  return decodeURIComponent(atob(str).replace(/(.)/g, function (m, p) {
    var code = p.charCodeAt(0).toString(16).toUpperCase();
    if (code.length < 2) {
      code = '0' + code;
    }
    return '%' + code;
  }));
}

module.exports = function(str) {
  var output = str.replace(/-/g, "+").replace(/_/g, "/");
  switch (output.length % 4) {
    case 0:
      break;
    case 2:
      output += "==";
      break;
    case 3:
      output += "=";
      break;
    default:
      throw "Illegal base64url string!";
  }

  try{
    return b64DecodeUnicode(output);
  } catch (err) {
    return Base64.atob(output);
  }
};

},{"Base64":4}],2:[function(require,module,exports){
'use strict';

var base64_url_decode = require('./base64_url_decode');
var json_parse = require('./json_parse');

module.exports = function (token) {
  if (!token) {
    throw new Error('Invalid token specified');
  }
  
  return json_parse(base64_url_decode(token.split('.')[1]));
};

},{"./base64_url_decode":1,"./json_parse":3}],3:[function(require,module,exports){
module.exports = function (str) {
  var parsed;
  if (typeof JSON === 'object') {
    parsed = JSON.parse(str);
  } else {
    parsed = eval('(' + str + ')');
  }
  return parsed;
};

},{}],4:[function(require,module,exports){
;(function () {

  var
    object = typeof exports != 'undefined' ? exports : this, // #8: web workers
    chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',
    INVALID_CHARACTER_ERR = (function () {
      // fabricate a suitable error object
      try { document.createElement('$'); }
      catch (error) { return error; }}());

  // encoder
  // [https://gist.github.com/999166] by [https://github.com/nignag]
  object.btoa || (
  object.btoa = function (input) {
    for (
      // initialize result and counter
      var block, charCode, idx = 0, map = chars, output = '';
      // if the next input index does not exist:
      //   change the mapping table to "="
      //   check if d has no fractional digits
      input.charAt(idx | 0) || (map = '=', idx % 1);
      // "8 - idx % 1 * 8" generates the sequence 2, 4, 6, 8
      output += map.charAt(63 & block >> 8 - idx % 1 * 8)
    ) {
      charCode = input.charCodeAt(idx += 3/4);
      if (charCode > 0xFF) throw INVALID_CHARACTER_ERR;
      block = block << 8 | charCode;
    }
    return output;
  });

  // decoder
  // [https://gist.github.com/1020396] by [https://github.com/atk]
  object.atob || (
  object.atob = function (input) {
    input = input.replace(/=+$/, '')
    if (input.length % 4 == 1) throw INVALID_CHARACTER_ERR;
    for (
      // initialize result and counters
      var bc = 0, bs, buffer, idx = 0, output = '';
      // get next character
      buffer = input.charAt(idx++);
      // character found in table? initialize bit storage and add its ascii value;
      ~buffer && (bs = bc % 4 ? bs * 64 + buffer : buffer,
        // and if not first of each 4 characters,
        // convert the first 8 bits to one ascii character
        bc++ % 4) ? output += String.fromCharCode(255 & bs >> (-2 * bc & 6)) : 0
    ) {
      // try to find character in table (0-63, not found => -1)
      buffer = chars.indexOf(buffer);
    }
    return output;
  });

}());

},{}],5:[function(require,module,exports){
var global=typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {};/*
 *
 * This is used to build the bundle with browserify.
 *
 * The bundle is used by people who doesn't use browserify.
 * Those who use browserify will install with npm and require the module,
 * the package.json file points to index.js.
 */
var jwt_decode = require('./lib/index');

//use amd or just throught to window object.
if (typeof global.window.define == 'function' && global.window.define.amd) {
  global.window.define('jwt_decode', function () { return jwt_decode; });
} else if (global.window) {
  global.window.jwt_decode = jwt_decode;
}
},{"./lib/index":2}]},{},[5])

;